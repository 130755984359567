import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Markdown from "react-commonmark"
import Frame from "../components/Frame"
import SEO from "../components/seo"
import ThermalBackground from "../components/ThermalBackground"
import Avatar from "../components/ui/Avatar"
import Cushion from "../components/ui/Cushion"
import Flex from "../components/ui/Flex"
import MaxWidth from "../components/ui/MaxWidth"
import Overlap from "../components/Overlap"
import Rectangle from "../components/ui/Rectangle"
import Show from "../components/ui/Show"
import Typography from "../components/ui/Typography"
import ConcaveBottom from "../components/ConcaveBottom"

const About = ({ data, location }) => {
  const {
    aboutJson: { title, image, content, theTeam } = {},
    contactDetailsJson,
  } = data
  return (
    <Frame
      currentPathname={location.pathname}
      footerBackgroundColor="white"
      contactDetails={contactDetailsJson}
    >
      <SEO title="About A-rated PowerFlush" />
      <ThermalBackground component="div">
        <ConcaveBottom fill="vLightGrey">
          <Cushion
            component="div"
            horizontal="small"
            top="xxlarge"
            bottom="xxxxlarge"
            responsive={[
              { minWidth: "palm", props: { horizontal: "medium" } },
              { minWidth: "tab", props: { horizontal: "xlarge" } },
            ]}
          >
            <Typography component="div" align="center">
              <Typography component="div" preset="hero" color="white">
                {title}
              </Typography>
            </Typography>
          </Cushion>
        </ConcaveBottom>
      </ThermalBackground>
      <ConcaveBottom fill="white">
        <MaxWidth maxWidth={1000} center>
          <Cushion
            horizontal="small"
            vertical="large"
            responsive={[
              { minWidth: "palm", props: { horizontal: "medium" } },
              { minWidth: "tab", props: { horizontal: "xlarge" } },
            ]}
          >
            <Overlap amount={160}>
              <Cushion bottom="medium">
                <Rectangle radius="rounded">
                  <img
                    src={image}
                    alt="About A-rated Powerflush"
                    style={{ width: "100%" }}
                  />
                </Rectangle>
              </Cushion>
              <Typography component="div" preset="body">
                <Markdown source={content} />
              </Typography>
            </Overlap>
          </Cushion>
        </MaxWidth>
      </ConcaveBottom>
      <Rectangle component="div" fill="white">
        <MaxWidth maxWidth={1000} center>
          <Cushion
            horizontal="small"
            vertical="large"
            responsive={[
              { minWidth: "palm", props: { horizontal: "medium" } },
              { minWidth: "tab", props: { horizontal: "xlarge" } },
            ]}
          >
            <Cushion bottom="large">
              <Typography component="div" preset="heading" color="black">
                Meet our team
              </Typography>
            </Cushion>
            {theTeam.map((teamMember, index) => {
              const { name, image, jobTitle, description } = teamMember

              return (
                <Typography
                  align="center"
                  responsive={[{ minWidth: "palm", props: { align: "left" } }]}
                >
                  <Cushion bottom="xlarge" style={{ position: "relative" }}>
                    <Flex
                      flexDirection="column"
                      alignItems="center"
                      responsive={[
                        {
                          minWidth: "palm",
                          props: { flexDirection: "row", alignItems: "center" },
                        },
                      ]}
                      style={{ position: "relative", zIndex: 2 }}
                    >
                      <Cushion
                        component="div"
                        bottom="small"
                        responsive={[
                          { minWidth: "palm", props: { right: "xlarge" } },
                        ]}
                        style={{ maxWidth: 140, flexGrow: 1 }}
                      >
                        <Rectangle border="thickBrandBlue">
                          <Avatar image={image} alt={name} size={140} />
                        </Rectangle>
                      </Cushion>
                      <div>
                        <Typography component="div" preset="heading">
                          {name}
                        </Typography>
                        <Typography component="div" preset="label">
                          {jobTitle}
                        </Typography>
                        <Typography component="div" preset="body">
                          {description}
                        </Typography>
                      </div>
                    </Flex>
                    <Show minWidth="palm">
                      <Rectangle
                        component="div"
                        fill="brandBlue"
                        style={{
                          position: "absolute",
                          height: "100%",
                          width: 5,
                          left: "calc((140px / 2) - (5px / 2))",
                          top: 0,
                          zIndex: 1,
                        }}
                      />
                    </Show>
                  </Cushion>
                </Typography>
              )
            })}
          </Cushion>
        </MaxWidth>
      </Rectangle>
    </Frame>
  )
}

About.propTypes = {
  location: PropTypes.shape({}).isRequired,
}
export default About

export const pageQuery = graphql`
  query AboutQuery {
    contactDetailsJson {
      email
      mobile
      landline
    }
    aboutJson {
      title
      image
      content
      theTeam {
        description
        image
        jobTitle
        name
      }
    }
  }
`
